<template>
  <div>
    <Header/>
    <div v-if="!global_data">
      Loading...
    </div>
    <div v-if="global_data">
      <div>
        Geographic zone:
        <select v-model="cur_zone" @change="select_zone(cur_zone)">
          <option v-for="(zone, index) in global_data.zones"
                  :key="index"
                  v-bind:value="zone.code">{{ zone.name }}
          </option>
        </select>
      </div>

      <div>
        Provider:
        <select v-model="cur_provider" @change="select_provider(cur_provider)">
          <option value="all">All</option>
          <option v-for="(provider, index) in global_data.provider_list"
                  :key="index"
                  v-bind:value="provider.slug">{{ provider.name }}
          </option>
        </select>
      </div>

      <div v-if="cur_provider !== 'all'">
        Per {{ global_data.providers[cur_provider].name }} region:

        <select v-model="cur_provider_region" @change="select_region(cur_provider_region)">
          <option value="all">All</option>
          <option v-for="(region_name, region_code) in global_data.providers[cur_provider].regions"
                  :key="region_code"
                  v-bind:value="region_code">{{ region_name }}
          </option>
        </select>
        <div>
          Check <a v-bind:href="provider_cheapest_url">cheapest {{ global_data.providers[cur_provider].name }}
          region</a>
        </div>
      </div>

      <br/><br/>

      <h3>{{ generate_title() }}</h3>
    </div>

    <instances-table :global_data="global_data"
                     :provider="cur_provider"
                     :provider_region="cur_provider_region"
                     :zone="cur_zone"/>

    <Footer/>
  </div>
</template>

<script>
import data from "@/data.js";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import InstancesTable from "@/components/InstancesTable";


export default {
  name: "InstancesPage",
  components: {
    Header,
    Footer,
    InstancesTable,

  },
  metaInfo() {
    return {
      title: this.generate_title()
    }
  },
  methods: {
    select_zone(zone_code) {
      this.$router.push(data.generate_url(zone_code, this.cur_provider, this.cur_provider_region));
    },
    select_provider(provider) {
      this.$router.push(data.generate_url(this.cur_zone, provider, "all"));
    },
    select_region(region_code) {
      this.$router.push(data.generate_url(this.cur_zone, this.cur_provider, region_code));
    },
    generate_title() {
      return data.generate_title(this.global_data, this.cur_zone, this.cur_provider, this.cur_provider_region)
    }
  },
  data() {
    let cur_zone = this.$route.params.zone;
    if (!cur_zone) cur_zone = "global";
    let cur_provider_region = this.$route.params.provider_region;
    if (!cur_provider_region) cur_provider_region = "all";
    let cur_provider = this.$route.params.provider;
    if (!cur_provider) cur_provider = "all";

    let provider_cheapest_url = null;
    if (cur_provider !== "all") {
      provider_cheapest_url = data.generate_url_cheapest_provider(cur_provider);
    }

    return {
      global_data: null,
      cur_provider,
      cur_provider_region,
      cur_zone,
      provider_cheapest_url,
    }
  },
  async created() {
    this.global_data = await data.load_global_data();
  }
}

</script>
