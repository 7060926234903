const VERSION = "prod";
const BASE_API_URL = `https://api.instance-pricing.com/${VERSION}`;
const BASE_STATIC_URL = `https://www.instance-pricing.com`;
const GLOBAL_DATA_URL = `${BASE_STATIC_URL}/global_data.json`;
const data_version = "01dec2020";

async function load_global_data() {
    const global_data_text = await fetch(GLOBAL_DATA_URL);
    const global_data = await global_data_text.json();

    // ZONES
    const zones_dic = {};
    for (const zone of global_data.zones) {
        zones_dic[zone.code] = zone;
    }
    global_data.zones_dic = zones_dic;
    // PROVIDERS
    let provider_list = [];
    for (const [provider_key, provider] of Object.entries(global_data.providers)) {
        let regions = [];
        for (const [region_code, region_name] of Object.entries(provider.regions)) {
            let region = {
                "code": region_code,
                "name": region_name,
            };
            regions.push(region);
        }
        let prov = {
            name: provider.name,
            slug: provider_key,
            regions,
            regions_dic: provider.regions
        };
        provider_list.push(prov)
    }
    global_data.provider_list = provider_list;
    return global_data;
}



function generate_title(global_data, zone, provider, region) {
    if (zone === "global") {
        if (provider === "all") {
            return `All instances`;
        } else {
            if (region === "all") {
                if (!global_data) {
                    return `Instances of ${provider}`;
                } else {
                    return `Instances of ${global_data.providers[provider].name}`;
                }
            } else {
                if (!global_data) {
                    return `Instances of ${provider} at ${region}`;
                } else {
                    return `Instances of ${global_data.providers[provider].name} at ${global_data.providers[provider].regions[region]}`;
                }
            }
        }
    } else {
        if (!global_data) {
            return `Instances`;
        }
        const zone_name = global_data.continents[zone];
        if (provider === "all") {
            return `Instances in ${zone_name}`;
        } else {
            if (region === "all") {
                if (!global_data) {
                    return `Instances of ${provider} in ${zone_name}`;
                } else {
                    return `Instances of ${global_data.providers[provider].name} in ${zone_name}`;
                }
            } else {
                if (!global_data) {
                    return `Instances of ${provider} at ${region} in ${zone_name}`;
                } else {
                    return `Instances of ${global_data.providers[provider].name} at ${global_data.providers[provider].regions[region]} in ${zone_name}`;
                }
            }
        }
    }
}

function generate_url(zone, provider, region) {
    if (zone === "global") {
        if (provider === "all") {
            return "/";
        } else {
            if (region === "all") {
                return `/provider=${provider}`;
            } else {
                return `/provider=${provider}/region=${region}`;
            }
        }
    } else {
        if (provider === "all") {
            return `/zone=${zone}`;
        } else {
            if (region === "all") {
                return `/zone=${zone}/provider=${provider}`;
            } else {
                return `/zone=${zone}/provider=${provider}/region=${region}`;
            }
        }
    }
}

function generate_url_cheapest_provider(provider) {
    return generate_url("global", provider, "all") + "/cheapest";
}

function generate_url_provider_instance_types(provider, instance) {
    return generate_url("global", provider, "all") + "/instance=" + instance;
}

const data = {
    generate_url,
    generate_url_cheapest_provider,
    generate_url_provider_instance_types,
    generate_title,
    load_global_data,
    BASE_API_URL: BASE_API_URL,
    BASE_STATIC_URL: BASE_STATIC_URL,
    data_version
};

export default data;
