<template>
  <div>
    <div v-if="loading || !global_data">
      Loading...
    </div>
    <div v-if="!loading && global_data">
      <instances-table-raw :instances="instances"
                           :headers="headers"
                           @refresh="refresh"
                           :scan_forward="scan_forward"
                           @update-scan-forward="update_scan_forward"
                           :sort_index="sort_index"
                           @update-sort-index="update_sort_index"
                           :generated_date="global_data.generated_date"></instances-table-raw>
      <div v-if="!fully_loaded">
        Loading new elements...<br/>
      </div>
      <div v-if="fully_loaded && instances.length === 0">
        No instances found
      </div>
      <br/><br/>
    </div>
  </div>
</template>

<script>
import data from "@/data.js";
import InstancesTableRaw from "@/components/InstancesTableRaw";

function generate_headers(currency, per_period) {
  let period;

  if (per_period === 'per_hour') {
    period = "h";
  } else {
    period = "unknown"
  }

  return [
    {
      text: 'Provider',
      align: 'start',
      value: 'provider',
      sortBy: 'price_hour_usd',
    },
    {text: 'Region', value: 'region_name'},
    {text: 'Region (code)', value: 'region_code'},
    {text: 'Name', value: 'name'},
    {
      text: 'vCPU', value: 'vCPU',
      sort_index: "cpu"
    },
    {
      text: 'RAM (MiB)', value: 'RAM_MiB',
      sort_index: "ram"
    },
    {text: 'Storage (GB)', value: 'storage'},
    {
      text: `Price/${period} (${currency})`,
      value: 'price',
      sort_index: "default"
    },
  ]
}

export default {
  name: "InstancesTable",
  props: {
    'global_data': Object,
    'provider': {default: "all", type: String},
    'provider_region': {default: "all", type: String},
    'region': String,
    'zone': {default: "global", type: String}
  },
  components: {
    InstancesTableRaw,
  },
  data() {
    const currency = "USD";
    const per_period = "per_hour";

    return {
      loading: true,
      params: {
        currency,
        per_period,
      },
      instances: [],
      last_key: null,
      fully_loaded: false,
      scan_forward: true,
      sort_index: "default",
      headers: generate_headers(currency, per_period),
    }
  },
  async created() {
    await this.load_next(this.last_key);
  },
  methods: {
    update_scan_forward(scan_forward) {
      this.scan_forward = scan_forward;
    },
    update_sort_index(sort_index) {
      this.sort_index = sort_index
    },
    async load_next() {
      if (this.fully_loaded) {
        return;
      }
      const qp = {
        currency: this.params.currency,
        per_period: this.params.per_period,
        zone: this.zone,
        provider: this.provider,
        provider_region: this.provider_region,
        version: data.data_version,
        sort_index: this.sort_index,
        scan_forward: this.scan_forward,
      };
      if (this.last_key) {
        qp.last_key = this.last_key;
      }
      const queryp = new URLSearchParams(qp).toString();
      const res = await fetch(`${data.BASE_API_URL}/instances?${queryp}`);
      const response = await res.json();

      if (response["items"]) {
        this.instances.push(...response["items"]);
      }
      if ("last_key" in response && response["last_key"]) {
        this.last_key = response["last_key"];
      } else {
        this.last_key = null;
        this.fully_loaded = true;
        setTimeout(function () {
          document.dispatchEvent(new Event('instance-table-rendered-event'))
        });
      }
      this.loading = false;
    },
    refresh() {
      this.fully_loaded = false;
      this.last_key = null;
      this.instances = [];
      this.load_next();
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(
            window.scrollY,
            document.documentElement.scrollTop,
            document.body.scrollTop) + window.innerHeight > document.documentElement.offsetHeight - 1

        if (bottomOfWindow) {
          this.load_next();
        }
      }
    }
  },
  mounted() {
    this.scroll();
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/styled-table.scss';
</style>
