<template>
  <div>
    <Header/>
    <div class="page">
      <h1>Privacy</h1>

      <p>Last updated&nbsp;June 21st, 2022</p>

      <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span
          data-custom-class="body_text">Thank you for choosing to be part of our community at Vinland SAS, doing business as <span
          style="color: #595959;"><span data-custom-class="body_text">Instance-Pricing</span></span> ("<span
          style="color: #595959;"><span
          data-custom-class="body_text">Instance-Pricing</span></span></span></span></span><span
          data-custom-class="body_text">", "we", "us", "our"). <span style="color: #595959;"><span
          data-custom-class="body_text">We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at</span></span> contact@instance-pricing.com.</span>
      </div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;">&nbsp;</span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span
          data-custom-class="body_text">When you <span style="color: #595959;"><span data-custom-class="body_text">visit our website <a
          href="https://www.instance-pricing.com" target="_blank" rel="noopener" data-custom-class="link">https://www.instance-pricing.com</a>,</span></span> and <span
          style="color: #595959;"><span data-custom-class="body_text">use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our&nbsp;</span></span></span><span
          style="color: #595959;"><span
          data-custom-class="body_text">Sites and our services.</span></span></span></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;">&nbsp;</span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span
          style="color: #595959;"><span data-custom-class="body_text">This privacy <span style="color: #595959;"><span
          data-custom-class="body_text">policy applies to all information collected through our</span></span> <span
          style="color: #595959;"><span data-custom-class="body_text">website (such as <a
          href="https://www.instance-pricing.com" target="_blank" rel="noopener" data-custom-class="link">https://www.instance-pricing.com</a>),</span></span></span><span
          data-custom-class="body_text">&nbsp;<span style="color: #595959;"><span data-custom-class="body_text">and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "<strong>Sites</strong>")</span></span>.</span></span></span></span>
      </div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;">&nbsp;</span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span
          style="color: #595959;"><span data-custom-class="body_text"><strong>Please read this privacy <span
          style="color: #595959;"><strong><span data-custom-class="body_text">policy carefully as it will help you make informed decisions about sharing your personal information with us</span></strong></span>.</strong></span></span></span></span>
      </div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;">&nbsp;</span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span
          style="color: #000000;"><strong><span
          data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;">&nbsp;</span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infocollect"
                                                                       data-custom-class="link"><span
          style="color: #595959;">1. WHAT INFORMATION DO WE COLLECT?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infouse" data-custom-class="link"><span
          style="color: #595959;">2. HOW DO WE USE YOUR INFORMATION?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><a href="#infoshare"
                                                                                                     data-custom-class="link">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span></span>
      </div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><a href="#whoshare"
                                                                                                     data-custom-class="link">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a></span></span>
      </div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#cookies" data-custom-class="link"><span
          style="color: #595959;">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#inforetain" data-custom-class="link"><span
          style="color: #595959;">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infosafe" data-custom-class="link"><span
          style="color: #595959;">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#infominors" data-custom-class="link"><span
          style="color: #595959;">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><a
          href="#privacyrights" data-custom-class="link">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#DNT" data-custom-class="link"><span
          style="color: #595959;">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#caresidents"
                                                                       data-custom-class="link"><span
          style="color: #595959;">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span></div>
      <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#policyupdates"
                                                                       data-custom-class="link"><span
          style="color: #595959;">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
      <div style="line-height: 1.5;"><a href="#contact" data-custom-class="link"><span
          style="color: #595959; font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
      <div style="line-height: 1.5;">&nbsp;</div>
      <div id="infocollect" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
          style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
          style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
          data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span></span></span></span></span>
      </div>
      <div style="line-height: 1.5;"><span style="color: #000000;" data-custom-class="heading_2"><span
          style="font-size: 15px;"><strong><u><br/></u></strong><strong>Information automatically collected</strong></span></span>
      </div>
      <div>
        <div>&nbsp;</div>
        <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
            style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span
            style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span
            data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong></span></span></span></span><span
            data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span
            style="font-size: 15px; color: #595959;"><span
            data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some information &mdash; such as IP address and/or browser and device characteristics &mdash; is collected automatically when you visit our <span
            style="color: #595959;"><em><span data-custom-class="body_text">Sites</span></em></span>.</em></span></span></span></span></span></span>
        </div>
      </div>
      <div style="line-height: 1.5;">&nbsp;</div>
      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We automatically collect certain information when you visit, use or navigate the <span
          style="color: #595959;"><span data-custom-class="body_text">Sites</span></span>. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our <span
          style="color: #595959;"><span data-custom-class="body_text">Sites</span></span> and other technical information. This information is primarily needed to maintain the security and operation of our <span
          style="color: #595959;"><span data-custom-class="body_text">Sites</span></span>, and for our internal analytics and reporting purposes.</span></span></span>
      </div>
      <div style="line-height: 1.5;">&nbsp;</div>
      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies. </span></span></span>
        <div style="line-height: 1.5;"><span style="color: #000000;" data-custom-class="heading_2"><span
            style="font-size: 15px;"><strong><u><br/></u></strong><strong>Information collected from other sources</strong></span></span>
        </div>
        <div>
          <div>&nbsp;</div>
          <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
              style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span
              style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span
              data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We may collect limited data from public databases, marketing partners, and other outside sources.</em></span></span></span></span></span></span>
          </div>
        </div>
        <div style="line-height: 1.5;">&nbsp;</div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. <span
            style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Examples of the information we receive from other sources include: social media profile information</span></span><span
            data-custom-class="body_text">; marketing leads and search results and links, including paid listings (such as sponsored links).</span></span></span></span>
        </div>
        <div style="line-height: 1.5;">&nbsp;</div>
        <div id="infouse" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
            style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
            style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
            data-custom-class="heading_1">2. HOW DO WE USE YOUR INFORMATION?</span></strong></span></span></span></span></span>
        </div>
        <div>
          <div style="line-height: 1.5;">&nbsp;</div>
          <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
              style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span
              style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span
              data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></span></span></span></span></span></span>
          </div>
        </div>
        <div style="line-height: 1.5;">&nbsp;</div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We use personal information collected via our <span
            style="font-size: 15px;"><span style="color: #595959;"><span
            data-custom-class="body_text">Sites</span></span></span> for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.</span></span></span>
        </div>
        <div style="line-height: 1.5;">&nbsp;</div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We use the information we collect or receive:</span></span></span>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Request Feedback.&nbsp;</strong><span
                            style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text">We may use your information to request feedback and to contact you about your use of our Sites</span></span></span>.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                            style="color: #595959;"><span data-custom-class="body_text"><strong>To protect our Sites.&nbsp;</strong><span
                            style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text">We may use your information as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring and prevention).</span></span></span></span></span></span></span>
                        </li>
                      </ul>
                      <p style="font-size: 15px;">&nbsp;</p>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>For other Business Purposes.</strong></span><span
                            data-custom-class="body_text">&nbsp;We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our <span
                            style="color: #595959; font-size: 15px;"><span
                            data-custom-class="body_text">Sites</span></span>, products, services, marketing and your experience.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div id="infoshare" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                          style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span class="control"
                                                                         style="color: #000000;"><strong><span
                          data-custom-class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></strong></span></span></span></span></span>
                      </div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em></span></span></span>
                      </div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We may process or share your data that we hold based on the following legal basis:</span></span></span>
                      </div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span></span></span>
                        </li>
                      </ul>
                      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">More specifically, we may need to process your data or share your personal information in the following situations:</span></span></span>
                      </div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                            style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the <span
                            style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text">Sites</span></span></span>, which will enable them to collect data about how you interact with the <span
                            style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text">Sites</span></span></span> over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</span></span></span>
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div id="whoshare" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                          style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span class="control"
                                                                         style="color: #000000;"><strong><span
                          data-custom-class="heading_1">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
                      </div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We only share information with the following third parties.</em></span></span></span>
                      </div>
                      <div style="line-height: 1.5;">&nbsp;</div>
                      <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We only share and disclose your information with the following third parties. We have categorised each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.</span></span></span>
                      </div>
                    </div>
                    <ul>
                      <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                          style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Communicate and Chat with Users</strong></span></span></span>
                      </li>
                    </ul>
                    <div style="margin-left: 40px;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                        data-custom-class="body_text"><span style="font-size: 15px;"><span
                        style="color: #595959;">Crisp</span></span></span></span></span></div>
                  </div>
                </div>
                <ul>
                  <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                      style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Content Optimisation</strong></span></span></span>
                  </li>
                </ul>
                <div style="margin-left: 40px;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                    data-custom-class="body_text"><span style="font-size: 15px;"><span style="color: #595959;">Google Site Search</span></span></span></span></span>
                </div>
                <ul>
                  <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                      style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Web and Mobile Analytics</strong></span></span></span>
                  </li>
                </ul>
                <div style="margin-left: 40px;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                    data-custom-class="body_text"><span style="font-size: 15px;"><span style="color: #595959;">Google Analytics</span></span></span></span></span>
                </div>
                <ul>
                  <li style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                      style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong>Advertisement</strong></span></span></span>
                  </li>
                </ul>
                <div style="margin-left: 40px;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                    data-custom-class="body_text"><span style="font-size: 15px;"><span style="color: #595959;">Google Adsense</span></span></span></span></span>
                </div>
                <div>&nbsp;</div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div id="cookies" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                    data-custom-class="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></span></span></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy<span
                    style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">.</span></span></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div id="inforetain" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                    data-custom-class="heading_1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>&nbsp;We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</em></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than <span
                    style="font-size: 15px;"><span style="color: #595959;"><span
                    data-custom-class="body_text">2 years</span></span></span>.</span></span></span></div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div id="infosafe" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                    data-custom-class="heading_1">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></span></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>&nbsp;We aim to protect your personal information through a system of organisational and technical security measures.</em></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our <span
                    style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Sites</span></span> is at your own risk. You should only access the services within a secure environment.</span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div id="infominors" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                    data-custom-class="heading_1">8. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></span></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span
                    data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We do not knowingly collect data from or market to children under 18 years of age.</em></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the <span
                    style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Sites</span></span>, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the <span
                    style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Sites</span></span>. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span
                    style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">contact@instance-pricing.com</span></span>.</span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div id="privacyrights" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                    style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                    data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></span></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span
                    data-custom-class="body_text"><strong><em>In Short:</em></strong><em> In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the <a
                    href="#contact" data-custom-class="link"><span style="font-size: 15px;"><span
                    style="color: #3030f1;">contact details</span></span></a> provided below. We will consider and act upon any request in accordance with applicable data protection laws.</span></span></span>
                </div>
                <div style="line-height: 1.5;">&nbsp;</div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</span></span></span>
                </div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;">&nbsp;</span></div>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                    style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span
                    style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text"><span
                    style="color: #3030f1;"><span data-custom-class="body_text"><a
                    href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank"
                    rel="noopener noreferrer" data-custom-class="link"><span style="font-size: 15px;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span>
                </div>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our <span
                  style="font-size: 15px;"><span style="color: #595959;"><span
                  data-custom-class="body_text">Sites</span></span></span>. To opt-out of interest-based advertising by advertisers on our <span
                  style="font-size: 15px;"><span style="color: #595959;"><span
                  data-custom-class="body_text">Sites</span></span></span> visit <span style="color: #3030f1;"><span
                  data-custom-class="body_text"><a href="http://www.aboutads.info/choices/" target="_blank"
                                                   rel="noopener noreferrer" data-custom-class="link"><span
                  style="font-size: 15px;">http://www.aboutads.info/choices/</span></a></span></span>. </span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="DNT" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                  data-custom-class="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. <span
                  style="color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text">No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</span></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="caresidents" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                  data-custom-class="heading_1">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></span></span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>&nbsp;Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with the <span
                  style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Sites</span></span>, you have the right to request removal of unwanted data that you publicly post on the <span
                  style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Sites</span></span>. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the <span
                  style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">Sites</span></span>, but please be aware that the data may not be completely or comprehensively removed from all our systems.</span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="policyupdates" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                  data-custom-class="heading_1">12. DO WE MAKE UPDATES TO THIS POLICY?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
              </div>
              <div style="line-height: 1.5;"><em>&nbsp;</em></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong> Yes, we will update this policy as necessary to stay compliant with relevant laws.</em></span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</span></span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="contact" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                  data-custom-class="heading_1">13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you have questions or comments about this policy, you may contact our Data Protection Officer (DPO), <span
                  style="color: #595959; font-size: 15px;"><span
                  data-custom-class="body_text">Vincent Juhel</span></span>, by email at <span
                  style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">contact@instance-pricing.com</span></span>,</span> or by post to:</span></span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><span
                  style="font-size: 15px;"><span style="color: #595959;"><span style="color: #595959;"><span
                  data-custom-class="body_text">Vinland SAS</span></span></span></span></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                  style="color: #595959;"><span data-custom-class="body_text">Vincent Juhel</span></span></span></span>
              </div>
              <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><span
                  style="color: #595959;"><span data-custom-class="body_text">61 Boulevard de Verdun, 35000 Rennes, France</span></span></span></span>
              </div>
              <div style="line-height: 1.5;"><span data-custom-class="body_text">Rennes<span
                  data-custom-class="body_text"><span style="color: #595959;"><span style="font-size: 15px;"><span
                  data-custom-class="body_text">, 35000</span></span></span></span></span></div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span
                  data-custom-class="body_text">France</span></span></span></div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div id="request" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span
                  style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span class="control" style="color: #000000;"><strong><span
                  data-custom-class="heading_1">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
              </div>
              <div style="line-height: 1.5;">&nbsp;</div>
              <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span
                  style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please <span
                  data-custom-class="body_text">submit a request form by clicking <span style="color: #3030f1;"><span
                  data-custom-class="body_text"><span style="font-size: 15px;"><a
                  href="https://app.termly.io/notify/49374914-c69e-4352-9c3f-24d4e4baad57" target="_blank"
                  rel="noopener noreferrer" data-custom-class="link">here</a></span></span></span></span></span><span
                  data-custom-class="body_text">. We will respond to your request within 30 days.</span></span></span>
              </div>
            </div>
            <div style="color: #595959; font-size: 14px; font-family: Arial; padding-top: 16px;">This privacy policy was
              created using <a style="color: #3030f1 !important;"
                               href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly&rsquo;s Privacy
                Policy Generator</a>.
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Privacy",
  components: {
    Header,
    Footer
  },
  metaInfo: {
    title: 'Privacy',
  }
}
</script>

<style scoped>
.page {
  text-align: left;
  margin: 5em;
}
</style>
