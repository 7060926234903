<template>
  <div>
    <template v-if="instances && instances.length > 0">
      <div class="last-generation-date">Last generation date: {{
          new Date(generated_date).toDateString()
        }}
      </div>
      <br style="clear: both;"/>
    </template>
    <table class="styled-table">
      <thead>
      <tr>
        <td v-for="(header, index) in headers" :key="index">
          <span v-if="header.sort_index"
                @click="$emit('update-scan-forward', !scan_forward); $emit('update-sort-index', header.sort_index); $emit('refresh');"
                v-bind:style="{cursor: 'pointer'}">
            {{ header.text }}
          </span>
          <span v-else>{{ header.text }}</span>
          <span v-if="header.sort_index && header.sort_index === sort_index">
            <span v-if="header.sort_index && scan_forward"
                  @click="$emit('update-scan-forward', false); $emit('refresh')"
                  v-bind:style="{cursor: 'pointer'}">↓</span>
            <span v-if="header.sort_index && !scan_forward"
                  @click="$emit('update-scan-forward', true); $emit('refresh');"
                  v-bind:style="{cursor: 'pointer'}">↑</span>
          </span>
        </td>
      </tr>
      </thead>
      <tbody>
      <template v-for="(instance, indexinst) in instances">
        <tr v-if="indexinst === 3" v-bind:key="indexinst">
          <td v-bind:colspan="headers.length"
              style="text-align: left; background-color: #c4dcd2; font-weight: bold">
            <a href="https://www.vultr.com/register/?ref=9172990">
              Have you tried Vultr cheap instances? Signup and compare prices ></a>
          </td>
        </tr>
        <tr v-if="indexinst === 9" v-bind:key="indexinst">
          <td v-bind:colspan="headers.length"
              style="text-align: left; background-color: #c4dcd2; font-weight: bold">
            <a href="https://www.cloudways.com/en/?id=1162394&a_bid=06721105">
              Optimizing? Have you considered Managed Cloud Hosting Platform? Try Cloudways >
            </a>
          </td>
        </tr>
        <tr :key="indexinst">
          <td v-for="(header, indexhead) in headers" :key="indexhead">
            <template v-if="!header.nolink && header.value === 'region_code' || header.value === 'region_name'">
              <a v-bind:href="generate_url('global', instance.provider, instance.region_code)">{{ instance[header.value] }}</a>
            </template>
            <template v-else-if="!header.nolink && header.value === 'cheapest_region_code' || header.value === 'cheapest_region_name'">
              <a v-bind:href="generate_url('global', instance.provider, instance.cheapest_region_code)">{{ instance[header.value] }}</a>
            </template>
            <template v-else-if="!header.nolink && header.value === 'provider'">
              <a v-bind:href="generate_url('global', instance.provider, 'all')">{{ instance[header.value] }}</a>
            </template>
            <template v-else-if="!header.nolink && header.value === 'name'">
              <a v-bind:href="generate_url_provider_instance_types(instance.provider, instance[header.value])">{{ instance[header.value] }}</a>
            </template>
            <template v-else>
              {{ instance[header.value] }}
            </template>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import data from "@/data";

export default {
  name: "InstancesTableRaw",
  props: {
    'instances': Array,
    'headers': Array,
    'scan_forward': Boolean,
    'sort_index': String,
    'generated_date': String,
  },
  data() {
    const currency = "USD";
    const per_period = "per_hour";

    return {
      params: {
        currency,
        per_period,
      },
      generate_url: data.generate_url,
      generate_url_provider_instance_types: data.generate_url_provider_instance_types,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/styled-table.scss';

.last-generation-date {
  float: right;
  color: gray;
  font-size: 0.85em;
  text-align: center;
  width: 100%;
}
</style>
