<template>
  <div>
    <a href="/" style="text-decoration: none"><h1>Instance Pricing (bêta)</h1></a>
    <p>Compare VM instances prices and performances</p>
    <br/>
  </div>

</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
}
</script>

<style scoped>

</style>
