<template>
  <div>
    <Header/>

    <h1>Cheapest {{ cur_provider_name }} region per instance type</h1>

    <p>
      See also: <a v-bind:href="generate_url('global', cur_provider, 'all')">instances in {{ cur_provider_name }} region</a>.<br/>
      For every instance type, the region where we found the cheapest rate:<br/>
    </p>

    <div v-if="!global_data || !instances">
      Loading...
    </div>

    <div v-if="instances">
      <instances-table-raw :instances="instances"
                           :headers="headers"
                           @refresh="function(){}"
                           :scan_forward="true"
                           @update-scan-forward="function(){}"
                           :sort_index="null"
                           @update-sort-index="function(){}"
                           :generated_date="global_data.generated_date"></instances-table-raw>

    </div>
    <br/>
    <Footer/>

  </div>
</template>

<script>
import data from "@/data.js";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import InstancesTableRaw from "@/components/InstancesTableRaw";

export default {
  name: "InstancesPage",
  components: {
    Header,
    Footer,
    InstancesTableRaw,
  },
  metaInfo() {
    let name = this.cur_provider_name;
    if (!name) name = this.cur_provider;
    return {
      title: `Cheapest ${name} region per instance type`
    }
  },
  data() {
    let cur_provider = this.$route.params.provider;
    if (!cur_provider) {
      throw "no provider given";
    }

    const headers = [
      {text: 'Instance type', value: 'name'},
      {text: 'vCPU', value: 'vCPU'},
      {text: 'RAM (MiB)', value: 'RAM_MiB'},
      {text: 'Cheapest Region', value: 'cheapest_region_name'},
      {text: 'Cheapest Region (code)', value: 'cheapest_region_code'},
    ];

    return {
      global_data: null,
      cur_provider,
      cur_provider_name: cur_provider,
      instances: null,
      headers,
      generate_url: data.generate_url
    }
  },
  async created() {
    this.global_data = await data.load_global_data();
    this.cur_provider_name = this.global_data.providers[this.cur_provider].name;
    const res = await fetch(`${data.BASE_STATIC_URL}/data/cheapest-region/${this.cur_provider}.json`);
    const response = await res.json();
    this.instances = response["items"];
    const cur_provider = this.cur_provider;
    this.instances.forEach(function(instance) { instance["provider"] = cur_provider; });
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/styled-table.scss';

</style>
