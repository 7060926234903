<template>
  <div>
    <div style="height: 5em;">
      <!-- empty space for footer -->
    </div>
    <div class="footer">
      Warnings: This site is not maintained nor affiliated with any provider. The data is not guaranteed, may not be
      accurate, nor exhaustive, nor current.<br/>
      Please report any bug or issue: <a
        href="mailto:contact@instance-pricing.com">contact@instance-pricing.com</a><br/>
      <br/>
      Copyright ©2022 -
      <router-link to="/terms/">Terms of use</router-link>
      -
      <router-link to="/privacy/">Privacy</router-link>
      -
      <router-link to="/disclaimer/">Disclaimer</router-link>
      -
      <a href="/provider=aws-ec2/cheapest/">Cheapest AWS-EC2 instances</a>
      -
      <a href="/provider=gcp/cheapest/">Cheapest GCP instances</a>
      -
      <a href="/provider=azure/cheapest/">Cheapest Azure instances</a>
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer.vue"
}
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f3f3f3;
  font-size: 0.85em;
}
</style>
