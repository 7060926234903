<template>
  <div id="app" class=".v-application">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import '@/external/crisp.js';

export default {
  name: 'App',
  metaInfo: {
    title: 'Instance Pricing',
    titleTemplate: '%s | Instance Pricing',
    meta: [
      {
        name: 'description',
        content: 'Compare VM instances prices and performances'
      },
      {
        property: 'og:description',
        content: 'Compare VM instances prices and performances'
      }
    ]
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;

}

a {
  color: #1a0dab;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

</style>
