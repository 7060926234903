import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

import VueRouter from 'vue-router';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import InstancesPage from '@/components/InstancesPage.vue';
import Terms from '@/components/Terms.vue';
import Privacy from '@/components/Privacy.vue';
import Disclaimer from '@/components/Disclaimer.vue';
import VueMeta from 'vue-meta';
import ProviderCheapest from "./components/ProviderCheapest";
import ProviderInstanceTypes from "@/components/ProviderInstanceTypes";

Vue.use(VueMeta);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/provider=:provider', component: InstancesPage },
    { path: '/provider=:provider/cheapest', component: ProviderCheapest },
    { path: '/provider=:provider/instance=:instance', component: ProviderInstanceTypes },
    { path: '/provider=:provider/region=:provider_region', component: InstancesPage },
    { path: '/zone=:zone', component: InstancesPage },
    { path: '/zone=:zone/provider=:provider', component: InstancesPage },
    { path: '/zone=:zone/provider=:provider/region=:provider_region', component: InstancesPage },
    { path: '/terms/', component: Terms },
    { path: '/privacy/', component: Privacy },
    { path: '/disclaimer/', component: Disclaimer },
    { path: '/', component: InstancesPage }
  ]
});


Sentry.init({
  Vue,
  dsn: "https://8b9ee34f68bb4b59800101380588688a@o977318.ingest.sentry.io/6520307",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app');
