<template>
  <div>
    <Header/>

    <h1>{{ cur_instance_type }} instance pricing of {{ cur_provider_name }}</h1>

    <p v-if="instances">
      Specs: {{ instances[0].vCPU }} vCPU | RAM {{ instances[0].RAM_MiB }} MiB | Storage {{ instances[0].storage }}
    </p>

    <p>
      In which region can you find the cheapest {{ cur_provider_name }} {{ cur_instance_type }} instance?<br/>
      We browsed
      <a v-bind:href="generate_url('global', cur_provider, 'all')">all instances types in every {{ cur_provider_name }}
        region</a>.<br/>
      We found instance type {{ cur_instance_type }} in all these regions:<br/>
    </p>

    <div v-if="!global_data || !instances">
      Loading...
    </div>

    <div v-if="instances">
      <instances-table-raw :instances="instances"
                           :headers="headers"
                           @refresh="function() {}"
                           :scan_forward="true"
                           @update-scan-forward="function() {}"
                           :sort_index="null"
                           @update-sort-index="function() {}"
                           :generated_date="global_data.generated_date"></instances-table-raw>
    </div>
    <br/>
    <Footer/>

  </div>
</template>

<script>
import data from "@/data.js";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import InstancesTableRaw from "@/components/InstancesTableRaw";

export default {
  name: "ProviderInstanceTypes",
  components: {
    Header,
    Footer,
    InstancesTableRaw,
  },
  metaInfo() {
    let name = this.cur_provider_name;
    if (!name) name = this.cur_provider;
    return {
      title: `${this.cur_instance_type} instance of ${name}`
    }
  },
  data() {
    let cur_provider = this.$route.params.provider;
    if (!cur_provider) {
      throw "no provider given";
    }

    let cur_instance_type = this.$route.params.instance;
    if (!cur_instance_type) {
      throw "no instance type given";
    }

    const headers = [
      {
        text: 'Provider',
        align: 'start',
        value: 'provider',
      },
      {text: 'Region', value: 'region_name'},
      {text: 'Region (code)', value: 'region_code'},
      {text: 'Name', value: 'name', nolink: true},
      {text: 'vCPU', value: 'vCPU'},
      {text: 'RAM (MiB)', value: 'RAM_MiB'},
      {text: 'Storage (GB)', value: 'storage'},
      {
        text: `Price/per_hour (USD)`,
        value: 'price',
      },
    ];

    return {
      global_data: null,
      cur_provider,
      cur_provider_name: cur_provider,
      cur_instance_type,
      instances: null,
      headers,
      generate_url: data.generate_url
    }
  },
  async created() {
    this.global_data = await data.load_global_data();
    this.cur_provider_name = this.global_data.providers[this.cur_provider].name;
    const url = `${data.BASE_API_URL}/provider/${this.cur_provider}/instance/${this.cur_instance_type}`;
    const res = await fetch(url);
    const response = await res.json();
    this.instances = response["items"];
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/styled-table.scss';

</style>
